<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-04">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">Contact Us</h1>
        <div class="feature-body">
          <p>BMSON 웹사이트 이용 관련 다양한 문의에 대한 연락처 정보입니다.</p>
        </div>
      </div>
    </div>

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb">Contact Us</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

<!--  내용이 들어가는 부분 s  -->
      <div class="section">
        <a :href="`mailto:${email}`" target="_blank">
         <img src="@/assets/images/tempImage.png" style="max-width: 1088px; width: 100%">
        </a>
        <div class="btn_area_center btn_listview">
          <button @click="copyClipboard">문의처 복사하기</button>
        </div>
      </div>
<!--  내용이 들어가는 부분 e  -->
    </div>
  </main>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      email: 'bmson_support@bms.com'
    }
  },
  methods: {
    copyClipboard() {
      this.$copyText(this.email);
      alert("클립보드에 문의처 이메일 주소를 복사하였습니다. Ctrl+V(붙여넣기) 를 사용하셔서 원하시는 곳에 이메일 주소를 붙여넣으시면 됩니다.")
    }
  },
}
</script>

<style scoped>

</style>
